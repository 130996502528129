import { QButton, QModal, QModalOrchestrator, QNavbar, QOverlay, useModalController } from "@qamf/lighthouse";
import { useAppSettings, useAppStoreManager, useObservability } from "@qamf/shell-app-sdk";
import { useOnline } from "@vueuse/core";
import { defineComponent, onMounted, ref } from "vue";

import QSettings from "./components/q-settings/q-settings.vue";
import { useTranslations } from "./i18n/create-translations";
import { useRouter } from "./router";
import { initModalUtil } from "./utilities/modals";
import { AppInsightsEvent } from "./utilities/observability-events";
import usePersistentStorage from "./utilities/usePersistentStorage";

export default defineComponent({
	name: "NeoverseApp",
	components: {
		QOverlay,
		QNavbar,
		QSettings,
		QButton,
		QModal,
		QModalOrchestrator
	},
	setup() {
		const { environment } = useAppSettings();
		const { trackTrace, trackEvent } = useObservability();
		const { translateKey } = useTranslations();
		const { setSystemId } = useAppStoreManager();
		const { getItem, removeItem } = usePersistentStorage();
		const isOnline = useOnline();
		const modalController = useModalController();

		const appVersion = __VERSION__;

		const deferredPrompt = ref<any>(null);
		const showSettings = ref(false);
		const isPopupOpen = ref(false);
		const areSettingsEnabled = ref(environment);
		const storageTerminalAuthInfo = getItem("terminalAuthInfo");
		const isAuthComplete = storageTerminalAuthInfo?.AuthComplete ?? false;

		const router = useRouter();
		const modules = router?.getRoutes().filter(r => !r.meta?.notShowing).map(
			r => r.meta?.formattedPath
				? ({
					...r,
					path: r.meta.formattedPath
				})
				: r
		);
		const openSettings = () => {
			showSettings.value = true;
		};

		const logOut = () => {
			setSystemId(null);
			removeItem("terminalAuthInfo");

			router.push({ name: "login" });
		};

		onMounted(() => {
			initModalUtil(modalController);
			window.addEventListener("beforeinstallprompt", (event) => {
				event.preventDefault();
				deferredPrompt.value = event;
				trackTrace({
					message: "Prompt: 'beforeinstallprompt' event was fired.",
					severityLevel: 1
				});
			});

			window.addEventListener("appinstalled", () => {
				trackTrace({
					message: "Prompt: PWA was installed.",
					severityLevel: 1
				});
				trackEvent(AppInsightsEvent.PwaInstalled);
				deferredPrompt.value = null;
			});
		});

		return {
			areSettingsEnabled,
			appVersion,
			modules,
			isPopupOpen,
			openSettings,
			showSettings,
			translateKey,
			isOnline,
			isAuthComplete,
			logOut
		};
	}
});
