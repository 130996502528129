import { getModuleInstance, ModuleDefinition, registerModule, use4DAgent, useAppSettings, useAppSettingsManager, useAppStore, useAppStoreManager, useCloudBackend, useObservability, useObservabilityManager, useSecurityManager } from "@qamf/shell-app-sdk";
import debounce from "lodash-es/debounce";

import { initMultiLanguage, useTranslations } from "./i18n/create-translations";
import { defineShellImplementations } from "./implementations";
import registerServiceWorker from "./registerServiceWorker";
import router from "./router";
import useEventsManager from "./utilities/events-manager";
import { showModalFatal } from "./utilities/modals";
import { AppInsightsEvent } from "./utilities/observability-events";
import usePersistentStorage from "./utilities/usePersistentStorage";

function initSettings() {
	const { setupAppSettings } = useAppSettingsManager();
	return setupAppSettings({
		launchModeResolver() {
			return Promise.resolve(__LAUNCH_MODE__);
		},
		versionResolver() {
			return Promise.resolve(__VERSION__);
		},
		async settingsResolver() {
			const contextFileUrl = __LAUNCH_MODE__ === "Standard"
				? "/context.json"
				: `/settings/${__ENVIRONMENT__}-${__CHANNEL__}.json`;
			const response = await fetch(contextFileUrl, { method: "GET", cache: "no-cache" });
			const res = await response.json().catch(e => console.warn(e));
			return res;
		}
	});
}

async function resolveAppContext() {
	const { setOption: setAgent4DOption } = use4DAgent();
	const { setOption: setCloudOption } = useCloudBackend();
	const { setSystemId } = useAppStoreManager();
	const { getItem } = usePersistentStorage();

	const storageTerminalAuthInfo = getItem("terminalAuthInfo");
	const lanIpAddress = storageTerminalAuthInfo?.LanIpAddress ?? null;
	const systemId = storageTerminalAuthInfo?.CenterId ?? null;

	const { launchMode, cloudBackendUrl } = useAppSettings();

	if (launchMode.value === "DevelopSession" && __CENTER_ID__)
		setSystemId(+__CENTER_ID__);
	else if (systemId)
		setSystemId(+systemId);

	setCloudOption("host", cloudBackendUrl.value);

	if (lanIpAddress)
		setAgent4DOption("host", `https://${lanIpAddress}:5253`); // FIXME: move port number to useAppSettings() (TS: #46452)
}
async function setupObservability() {
	const { setup, setCommonPropsResolver } = useObservabilityManager();
	const { appInsightsConnString } = useAppSettings();
	if (!appInsightsConnString.value) throw new Error("Application Insights Connection String is not defined");

	setup({
		config: {
			connectionString: appInsightsConnString.value,
			maxAjaxCallsPerView: -1,
			disableExceptionTracking: true,
			disableTelemetry: __LAUNCH_MODE__ !== "Standard"
		}
	});
	setCommonPropsResolver(function commonObservabilityProps() {
		const route = router.currentRoute;
		const { launchMode, version, channel, environment, cloudBackendUrl } = useAppSettings();
		const { systemId } = useAppStore();
		const storageTerminalAuthInfo = usePersistentStorage().getItem("terminalAuthInfo");
		const agent4DUrl = storageTerminalAuthInfo?.LanIpAddress ?? null;
		const commonProperties = {
			routePath: route.value.fullPath ?? "",
			routeName: route.value.name ?? "",
			launchMode,
			channel,
			environment,
			version,
			cloudBackendUrl,
			agent4DUrl,
			systemId: systemId.value
		};
		return commonProperties;
	});
}

function initErrorHandling() {
	const fatalErrors: any[] = [];
	const logErrors = debounce(() => {
		const printErrorEl = (el: any, title?: string) => {
			console.groupCollapsed("\x1b[31m%s\x1b[0m", title ?? el.error.message);
			console.info(el.datetime);
			console.error(el.error);
			if (el.extras)
				console.info(el.extras);
			console.groupEnd();
		};
		if (fatalErrors.length === 1)
			printErrorEl(fatalErrors[0], "A fatal error occurred");

		else {
			console.groupCollapsed("\x1b[31m%s\x1b[0m", `Intercepted fatal errors: ${fatalErrors.length}`);
			fatalErrors.forEach(er => printErrorEl(er));
			console.groupEnd();
		}
	}, 1000);
	const { trackException, trackEvent } = useObservability();
	const { onError, emitError } = useEventsManager();
	const { translateKey } = useTranslations();
	onError(async(error, extras) => {
		const tracked = await trackException(error, extras);
		fatalErrors.push({ error, extras, datetime: new Date() });
		logErrors();

		trackEvent(AppInsightsEvent.FatalError, {
			description: "Unhandled exception feedback to user",
			error: tracked?.exception,
			errorName: tracked?.exception.name,
			errorStack: tracked?.exception.stack,
			errorMessage: tracked?.exception.message,
			errorExtras: tracked?.properties
		}, true);

		showModalFatal(translateKey("modal_fatal_error_title"), translateKey("modal_fatal_error_desc"));
	});
	window.onerror = (message, source, lineno, colno, error) => {
		if (!error) return;

		emitError(error, { message, source, lineno, colno });
		return true;
	};
	window.onunhandledrejection = event => {
		event.preventDefault();
		if (event.reason instanceof Error) emitError(event.reason);
		else emitError(new Error("Promise Rejection"), event.reason);
	};
}

function unwrapModuleDefinition(module: any): ModuleDefinition {
	return module.default || module;
}
async function registerModules() {
	const { trackEvent } = useObservability();

	trackEvent(AppInsightsEvent.ModuleRequest, { moduleId: "agent4d" });
	const modAgent4D = await import("@mod-neoverse").then(unwrapModuleDefinition);
	trackEvent(AppInsightsEvent.ModuleDownloaded, { moduleId: "agent4d" });

	trackEvent(AppInsightsEvent.ModuleSetupStart, { moduleId: "agent4d" });
	await registerModule({
		appName: "Neoverse",
		appVersion: __VERSION__,
		basePath: "/agent4d"
	}, modAgent4D);

	const component = getModuleInstance(modAgent4D.id)?.component;
	if (!component) throw new Error("Module component not found");
	router.addRoute({
		path: "/agent4d/:pathMatch(.*)*",
		name: "Neoverse",
		component,
		meta: {
			requiresAuth: true,
			formattedPath: "/agent4d"
		}
	});
	trackEvent(AppInsightsEvent.ModuleSetupEnd, { moduleId: "agent4d" });
}

function initFunctionalities() {
	const { setupSecurity } = useSecurityManager();
	const userFunctionalities = {
		AccessNeoverseModule: {
			Enabled: true,
			Experimental: false
		}
	};
	setupSecurity(userFunctionalities);
}

export default async function boot() {
	await initSettings();
	defineShellImplementations();
	await resolveAppContext();
	await Promise.all([
		initMultiLanguage(),
		setupObservability()
	]);
	initErrorHandling();
	registerServiceWorker();
	initFunctionalities();
	await registerModules();
}
